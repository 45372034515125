var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-table"},[_c('b-table-simple',{staticStyle:{"border-radius":"8px","border":"1px solid var(--neutral-300)"},attrs:{"responsive":"","borderless":"","fixed":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"width":"5%"}},[_c('Check',{attrs:{"value":_vm.checkAll},on:{"change":function($event){return _vm.checkAllOptions()}},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1),_c('b-th',{attrs:{"width":"15%"}},[_c('Ellipsis',[_vm._v("Situação")])],1),_c('b-th',{attrs:{"width":"30%"}},[_c('Ellipsis',[_vm._v("Sala")])],1),_c('b-th',{attrs:{"width":"40%"}},[_c('Ellipsis',[_vm._v("Exame/Procedimento")])],1),_c('b-th',{attrs:{"width":"10%"}})],1)],1),(_vm.groupedItems)?[_c('b-tbody',[_vm._l((Object.keys(_vm.groupedItems)),function(groupKey){return _vm._l((_vm.groupedItems[groupKey]),function(item,index){return _c('b-tr',{key:item.id,class:[
              index + 1 === _vm.groupedItems[groupKey].length
                ? 'boundary'
                : 'attendance'
            ]},[(_vm.groupedItems[groupKey].length === 1 || groupKey === 'pending')?[_c('b-td',{staticClass:"checkbox-td"},[(
                  item.attendance === null ||
                  (item.attendance &&
                    !['FINISHED', 'CANCELED'].includes(
                      item.attendance.status
                    ))
                )?_c('label',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled(item)},domProps:{"value":item,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item)>-1:(_vm.selected)},on:{"click":function($event){return _vm.checkOptions(item, groupKey)},"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}}),_c('span',{staticClass:"checkmark",class:{ disabled: _vm.isDisabled(item) }},[_c('CheckIcon')],1)]):_vm._e()])]:(
              groupKey !== 'pending' &&
              index === 0 &&
              _vm.groupedItems[groupKey].length > 1)?[_c('b-td',{staticClass:"grouped-check",attrs:{"rowspan":_vm.groupedItems[groupKey].length}},[(
                  item.attendance === null ||
                  (item.attendance &&
                    !['FINISHED', 'CANCELED'].includes(
                      item.attendance.status
                    ))
                )?_c('label',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled(item)},domProps:{"value":item,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item)>-1:(_vm.selected)},on:{"click":function($event){return _vm.checkOptions(item, groupKey)},"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}}),_c('span',{staticClass:"checkmark",class:{ disabled: _vm.isDisabled(item) }},[_c('CheckIcon')],1)]):_vm._e()])]:_vm._e(),_c('b-td',{staticClass:"td-body"},[(item.attendance === null)?[_c('b-button',{staticClass:"option pending",attrs:{"pill":"","disabled":""}},[_c('p',[_c('strong',[_vm._v("Pendente")])])])]:[(
                    item.attendance.status === 'PENDING' ||
                    item.attendance.status === 'OPENED'
                  )?_c('b-button',{staticClass:"option pending",attrs:{"pill":"","disabled":""}},[_c('p',[_c('strong',[_vm._v("Pendente")])])]):_vm._e(),(item.attendance.status === 'PAUSED')?_c('b-button',{staticClass:"option waiting",attrs:{"pill":"","disabled":""}},[_c('p',[_c('strong',[_vm._v("Pausado")])])]):_vm._e(),(item.attendance.status === 'FINISHED')?_c('b-button',{staticClass:"option finished",attrs:{"pill":"","disabled":""}},[_c('p',[_c('strong',[_vm._v("Realizado")])])]):_vm._e(),(item.attendance.status === 'CANCELED')?_c('b-button',{staticClass:"option canceled",attrs:{"pill":"","disabled":""}},[_c('p',[_c('strong',[_vm._v("Cancelado")])])]):_vm._e()]],2),_c('b-td',{staticClass:"td-body"},[_c('span',[_c('Ellipsis',[_vm._v(_vm._s(item?.essilor_item?.room_name || '-'))])],1)]),_c('b-td',{staticClass:"td-body"},[(item.item.clinic_procedure)?[_c('ProcedureLine',{attrs:{"ellipsis":"","procedure":item.item.clinic_procedure,"hideType":""}})]:[_c('ProcedureLine',{attrs:{"ellipsis":"","procedure":item.item,"hideType":""}})]],2),(item.attendance && item.attendance.status === 'FINISHED')?[(index === 0 && _vm.groupedItems[groupKey].length > 1)?[_c('b-td',{staticClass:"td-body action text-right p-3",attrs:{"rowspan":_vm.groupedItems[groupKey].length}},[_c('span',[_c('b-button',{staticClass:"clipboard-button",attrs:{"id":`btn-history-${item.id}`},on:{"click":function($event){return _vm.openAttendanceOnNewTab(
                          `pacientes/${_vm.exam.patient_id}/exame/${item.attendance.id}`
                        )}}},[_c('Clipboard')],1),_c('b-tooltip',{attrs:{"target":`btn-history-${item.id}`,"placement":"bottom"}},[_vm._v(" Visualizar Histórico ")])],1)])]:(_vm.groupedItems[groupKey].length === 1)?[_c('td',{staticClass:"td-body action text-right p-3"},[_c('span',[_c('b-button',{staticClass:"clipboard-button",attrs:{"id":`btn-history-${item.id}`},on:{"click":function($event){return _vm.openAttendanceOnNewTab(
                          `pacientes/${_vm.exam.patient_id}/exame/${item.attendance.id}`
                        )}}},[_c('Clipboard')],1),_c('b-tooltip',{attrs:{"target":`btn-history-${item.id}`,"placement":"bottom"}},[_vm._v(" Visualizar Histórico ")])],1)])]:_vm._e()]:_vm._e(),(
                groupKey !== 'pending' &&
                index === 0 &&
                !['FINISHED', 'CANCELED'].includes(item.attendance.status)
              )?[_c('td',{staticClass:"text-right action td-body",attrs:{"rowspan":_vm.groupedItems[groupKey].length}},[_c('b-button',{staticClass:"refresh-button",attrs:{"id":`btn-refresh-${item.id}`},on:{"click":function($event){return _vm.resetAttendanceAndAttendanceItems({
                      attendance: item.attendance.id
                    })}}},[_c('Refresh')],1),_c('b-tooltip',{attrs:{"target":`btn-refresh-${item.id}`,"placement":"bottom"}},[_vm._v(" Reiniciar os Exames ")])],1)]:_vm._e()],2)})})],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }